@use "../styles.scss";

.page-title-container {
  background: styles.$light-grey;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 192.49 111.13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0 110.05 15.25-26.31h-15.25v-.74h15.31l-15.31-26.55v-1.53l16 27.62 15.62-27-15.86-27.39h-15.76v-.77h47.38l-15.59-27h-31.68l-.11.19v-.57h32.47l15.82 27.41h31.17l-15.83-27.41h.89l15.83 27.41h31.21l-15.83-27.41h.9l15.56 27 15.63-27h.89l15.58 27 15.6-27h32.6v.56l-15.49 26.82h15.46v.77h-15.46l15.45 26.77v1.54l-16.08-27.94-15.61 27.05 15.83 27.43h15.9v.77h-47.49l15.59 27h31.53l.4-.69v1.08h-32.6l-15.85-27.45h-31.18l15.81 27.42h-.89l-15.81-27.42h-31.21l15.83 27.42h-.89l-15.61-27-15.6 27h-.89l-15.63-26.99-15.58 27h-32.42zm95.47-54.05h-31.16l-15.83 27.37 15.59 27zm48.15 27-15.62-27h-31.64l-15.58 27zm1.11-55.13 15.59 27 31.39-54.49h-31.15l-15.83 27.43zm-.45.77-15.58 27 6 10.37 9.84 17h31.16l-31.42-54.4zm-47.54 26.53h31.26l15.84-27.37-15.57-27.02zm-48-27 15.59 27h31.51l15.66-27zm-.74 54.42 15.6-27-15.78-27.44h-31.17zm-47.51 28.15h31.3l5.46-9.46 10.35-17.92-15.6-27.02z' fill='rgba(250,250,250,0.3)'/%3E%3C/svg%3E");
  background-size: 250px;
}

.page-title {
  @extend .accent-font;
  padding: 60px 32px;
  max-width: styles.$max-page-width;
  box-sizing: border-box;
  margin: 0 auto;
  font-size: 2em;
  color: black;
  font-weight: 700;
  a {
    color: black;
  }
}

.product-display-list, .search-display-list {
  @extend .standard-page-size;
  display: grid;
  grid-template-rows: auto;
}

.load-more-results {
  text-decoration: underline;
  text-align: center;
  font-size: 1.1em;
  font-weight: 600;
  padding: 14px;
  cursor: pointer;
  background: white;
}

.search-display-list {
  grid-template-rows: auto 50px auto auto;
}

.query-list {
  padding: 16px 0;
}

.query-list-item {
  border-radius: 24px;
  padding: 8px 16px 8px 26px;
  font-size: 0.85em;
  display: inline-block;
  color: white;
  background: styles.$brand-primary;
  margin-right: 6px;
  position: relative;
  cursor: pointer;
  &:before, &:after {
    content: "";
    position: absolute;
    left: 8px;
    top: 50%;
    width: 10px;
    height: 2px;
    background: white;
  }
  &:before {
    transform: translateY(-50%) rotate(-45deg);
  }
  &:after {
    transform: translateY(-50%) rotate(45deg);
  }
  &:hover {
    background: styles.$dark-grey;
  }
}

.catalog {
  @extend .standard-page-size;
  @media #{styles.$max_media_size_small}{
    padding: 32px;
  }
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.category {
  @extend .shadowed;
  background: white;
  font-size: 1.9em;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  width: 300px;
  height: 260px;
  color: styles.$brand-black;
  cursor: pointer;
  text-decoration: none;
  &.inverse {
    color: white;
    background: styles.$brand-black;
  }
  &:hover {
    text-decoration: underline;
    .keytrak-go-icon {
      background-image: url("/assets/keytrak-go-hover.svg");
    }
  }
  @media #{styles.$max_media_size_tiny}{
    width: 100%;
    padding: 24px 60px;
    justify-content: left;
    font-size: 1.8em;
    height: auto;
    .category-title-text {
      vertical-align: middle;
      display: inline-block;;
    }
  }
}

.category-title {
  @extend .accent-font;
  text-align: center;
}

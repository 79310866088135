@use "../styles.scss";

$button-width: 180px;
$button-height: 40px;

button:last-of-type {
  margin-right: 0;
}

@mixin keytrak-button($height, $bg-color, $txt-color) {
  border: none;
  height: $height;
  line-height: $height;
  outline: none;
  color: $txt-color;
  font-weight: 600;
  font-size: 1em;
  cursor: pointer;
  background: $bg-color;
  &:hover {
    background: darken($bg-color, 5%);
  }
}

@mixin outline-button($height, $bg-color, $txt-color) {
  height: $height;
  line-height: $height;
  outline: none;
  color: $bg-color;
  font-weight: 600;
  font-size: 1em;
  cursor: pointer;
  border: 1px solid $bg-color;
  background: transparent;
  &:hover {
    background: $bg-color;
    color: $txt-color;
  }
}

.header-search-button {
  @include keytrak-button(styles.$header-height, styles.$brand-primary, white);
  text-decoration: underline;
  position: relative;
}

.primary-button {
  @include keytrak-button(100%, styles.$brand-primary, white);
  &.disabled {
    background: transparent !important;
    color: styles.$brand-primary;
    outline: 2px solid styles.$brand-primary;
  }
}

.mobile-large {
  @media #{styles.$max_media_size_tiny}{
    width: 100% !important;
  }
}

.secondary-button {
  @include keytrak-button(100%, styles.$dark-grey, white);
}

.primary-button-standard {
  @include keytrak-button($button-height, styles.$brand-primary, white);
  width: $button-width;
  margin: 0 6px;
  &.disabled {
    background: white;
    border: 1px solid styles.$brand-primary;
    color: styles.$brand-primary;
    cursor: default;
  }
}

.secondary-button-standard {
  @include keytrak-button($button-height, styles.$brand-black, white);
  width: $button-width;
  margin: 0 6px;
}

.primary-button, .secondary-button {
  &.full-width {
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    height: $button-height;
    line-height: $button-height;
  }
  &.basic {
    text-align: center;
    padding: 12px 30px;
    font-size: 0.9em;
  }
  &.basic-wide {
    text-align: center;
    padding: 12px 30px;
    font-size: 0.9em;
    width: 100%;
  }
}

.login-button {
  @extend .full-width;
  @extend .primary-button;
}

.add-to-cart{
  @extend .full-width;
  @extend .primary-button;
  position: relative;
  padding-right: $button-height;
  transition: all 0.13s ease-out;
  &:after {
    position: absolute;
    content: "+";
    text-align: center;
    line-height: 36px;
    font-size: 1.8em;
    right: 0;
    top: 0;
    bottom: 0;
    width: $button-height;
    background: fade_out(styles.$brand-black, 0.8);
  }
  &.disabled {
    &:after {
      background: transparent;
      content: "";
      background-image: url("/assets/keytrak-cart-spinner-no-circle-red.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 70%;
      animation: 1.4s ease spinner;
      animation-iteration-count: infinite;
    }
  }
  &.failed {
    &:after {
      display: none;
    }
  }
  &.success {
    background-color: styles.$dark-green;
    &:after {
      content: "";
      background-image: url("/assets/keytrak-check.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 70%;
    }
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.outline-button {
  @include outline-button($button-height, styles.$brand-black, white);
  @extend .primary-button;
  width: $button-width;
  margin: 0 6px;
}

.link-btn {
  vertical-align: middle;
  font-size: 1.1em;
  display: inline-flex;
  padding: 10px 10px 20px;
  text-decoration: underline;
  cursor: pointer;
  color: styles.$dark-grey;
  &.red {
    color: styles.$brand-primary;
  }
}

.sub-text-button {
  color: styles.$brand-primary;
  display: block;
  text-decoration: underline;
  cursor: pointer;
  //text-align: center;
  padding: 10px 0 0;
  //font-size: 0.9em;
}

.checkout-button {
  width: 220px;
}

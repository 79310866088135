@use "../styles.scss";

.lockout {
  padding: 10%;
}

.keytrak-lockout-logo {
  background-image: url("/assets/keytrak.svg");
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  width: 200px;
  height: 160px;
}

.lockout-text {
  padding-left: 24px;
  border-left: 3px solid styles.$brand-primary;
}

.lockout-subtext {
  font-size: 1.3em;
  font-weight: 400;
  color: styles.$dark-grey;
  strong {
    color: styles.$brand-primary;
  }
}

.lockout-link-btn {
  padding: 0 8px;
  text-decoration: underline;
  color: styles.$brand-primary;
  cursor: pointer;
}

@use "../styles.scss";

.navigation-button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  padding-bottom: 10px;
}

.checkout-page-container {
  @extend .standard-page-size;
  @include styles.make-column-grid(1fr 400px);
  grid-gap: 20px;
  @media #{styles.$max_media_size_small}{
    display: flex;
    flex-flow: column;
  }
}

.payment-verify-container {
  @extend .standard-page-size;
}

.shipping-notice {
  font-size: 1em;
  font-weight: 600;
  padding: 12px;
  border-left: 2px solid styles.$brand-primary;
  color: styles.$brand-black;
  background: fade_out(styles.$brand-primary, 0.85);
  margin-bottom: 12px;
}

.checkout-progress {
  grid-column: 1/span 1;
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 40px;
  &:after, &:before {
    content: "";
    position: absolute;
    background: fade_out(styles.$grey, 0.5);
    height: 4px;
    top: 7px;
    transform: translateY(-50%);
    transition: transform 1s ease;
  }
  &:before {
    width: 99%;
  }
  &:after {
    transform: scaleX(0) translateY(-50%);
    background: styles.$brand-primary;
    transform-origin: left;
    width: 100%;
  }
  &.checkmark-1:after {
    transform: scaleX(0.33) translateY(-50%);
  }
  &.checkmark-2:after {
    transform: scaleX(0.66) translateY(-50%);
  }
  &.checkmark-3:after {
    transform: scaleX(0.99) translateY(-50%);
  }
  .checkout-progress-step {
    content: "";
    width: 14px;
    height: 14px;
    background: styles.$grey;
    position: relative;
    .checkout-progress-step-text {
      @extend .accent-font;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 100%);
      bottom: 0;
      font-size: 1.4em;
      font-weight: 700;
      width: 200px;
      color: styles.$grey;
      text-align: center;
    }
    &.filled {
      background: styles.$brand-primary;
      .checkout-progress-step-text {
        color: styles.$brand-black;
      }
    }
  }
  @media #{styles.$max_media_size_small}{
    order: 1;
  }
}

.checkout-summary {
  @extend .shadowed;
  background: white;
  padding: 20px;
  table {
    width: 100%;
  }
}

.checkout-summary-item {
  padding: 8px 0;
  font-size: 1.1em;
}

.checkout-summary-item-price {
  color: black;
  font-weight: 700;
  padding: 10px 0 10px 10px;
  vertical-align: top;
  text-align: right;
}

.checkout-item-quantity {
  font-size: 0.8em;
  color: styles.$dark-grey;
}

hr {
  margin: 0 20px;
  background: styles.$grey;
  opacity: 0.4;
}

.checkout-summary-item-name {
  font-weight: 500;
  color: styles.$dark-grey;
  padding: 10px 10px 10px 0;
  letter-spacing: -0.5px;
  .item-quantity {
    color: black;
    font-weight: 700;
    padding-right: 6px;
  }
}

.checkout-summary-subitem {
  font-weight: 500;
  color: styles.$dark-grey;
  strong {
    color: black;
  }
}

.checkout {
  @extend .shadowed;
  background: white;
  padding: 32px;
  margin-bottom: 24px;
  @media #{styles.$max_media_size_small}{
    padding: 24px;
  }
}

.checkout-selection-option {
  color: styles.$dark-grey;
  &:hover {
    cursor: pointer;
    color: styles.$brand-black;
    text-decoration: underline;
  }
  &.selected {
    color: styles.$brand-primary;
  }
}

.required-fields {
  color: styles.$brand-primary;
  font-style: italic;
  font-size: 1.1em;
  float: left;
}

.checkout-container {
  grid-column: 1 / span 1;
  @media #{styles.$max_media_size_small}{
    order: 4;
  }
}

.checkout-summary-container {
  @media #{styles.$max_media_size_small}{
    order: 3;
  }
}

.checkout-go-back {
  grid-column: 1 / span 2;
  color: styles.$brand-black;
  font-size: 0.9em;
  font-weight: 600;
  outline: none;
  text-decoration: underline;
  span {
    cursor: pointer;
  }
  @media #{styles.$max_media_size_small}{
    order: 2;
  }
}

@use "../styles.scss";

.orders-container {
  @extend .standard-page-size;
}

.order-number {
  font-size: 2em;
  color: styles.$brand-black;
  font-weight: 700;
  position: relative;
  padding: 12px;
  &:before {
    content: "#";
    position: relative;
    left: -16px;
    color: styles.$grey;
    font-style: italic;
  }
}

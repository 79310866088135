@use "../styles.scss";

.address-container {
  border: 1px solid styles.$grey;
  padding: 12px 24px;
  margin-bottom: 16px;
  position: relative;
}

.address-default-toggle-container {
  position: absolute;
  top: 16px;
  right: 24px;
}

.address-buttons {
  text-align: right;
  margin-top: 12px;
  @media #{styles.$max_media_size_small}{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    button {
      width: 100%;
      margin: 0;
    }
  }
  @media #{styles.$max_media_size_tiny}{
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: unset;
  }
}

.address-header {
  color: styles.$dark-grey;
  font-size: 0.9em;
  margin-top: 4px;
}

.address-detail {
  color: styles.$brand-black;
  font-weight: 600;
  &.bold {
    font-weight: 700;
  }
}

.address-details {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 1.3em;
  li {
    margin: 0;
    padding: 4px 0;
  }
}

.saved-address {
  padding: 16px 32px;
}

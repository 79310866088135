@use "../styles.scss";

.table-buttons-holder {
  @media #{styles.$max_media_size_tiny}{
    display: grid;
    button {
      width: 100%;
      margin: 16px 0 0 ;
      box-sizing: border-box;
    }
  }
}

.header-row {
  @extend .shadowed;
}

.keytrak-table {
  border-collapse: collapse;
  border: none;
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 20px;
  padding: 0;
  th {
    @extend .accent-font;
    font-size: 1.4em;
    font-weight: 600;
    text-align: left;
    padding: 20px;
    color: #888;
  }
  td,th {
    border: 1px solid #ccc;
    &:last-of-type {
      text-align: center;
    }
  }
  td {
    color: styles.$brand-black;
    font-weight: 600;
    font-size: 1.1em;
    padding: 8px 20px;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}

.total-row td{
  font-weight: 800 !important;
}

.table-date, .cart-item-list, .order-products {
  @media #{styles.$max_media_size_tiny}{
    display: none;
  }
}

.table-id {
  font-weight: 700 !important;
}

.order-info-button {
  text-decoration: underline;
  color: styles.$brand-primary;
}

.load-more-cell {
  padding: 16px 20px !important;
  text-decoration: underline;
  cursor: pointer;
}

.loading-cell {
  padding: 16px 20px !important;
  color: styles.$brand-primary !important;
}

.order-info-table {
  font-size: 1.2em;
  margin: 12px 0;
  width: 100%;
  border-collapse: collapse;
  td {
    padding: 8px 14px;
    font-weight: 600;
    border: 1px solid #ccc;
    color: styles.$brand-black;
  }
  .td-quantity {
    font-weight: 500;
    color: styles.$dark-grey;
  }
}

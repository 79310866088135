@use "sass:string";

// Brand
$brand-primary: #E81725;
$brand-primary-dark: #b20612;
$brand-black: #262626;
$brand-white: #EFEFEF;
$brand-secondary: #BA9D62;

$green: #49DD3B;
$dark-green: #0ea600;

// Greyscale
$light-grey: #b0adad;
$grey: #8F8F8F;
$dark-grey: #6B6B6B;
$black-grey: #383636;

$primary-font: 'Titillium Web', sans-serif;
$accent-font: 'Teko', sans-serif;

$min_media_size_extra_large: "(min-width: 1756px)";
$media_size_large: "(min-width: 1487px) and (max-width: 1755px)";
$max_media_size_large: "(max-width: 1755px)";
$media_size_medium: "(min-width: 1201px) and (max-width: 1486px)";
$max_media_size_medium: "(max-width: 1486px)";
$media_size_small: "(min-width: 921px) and (max-width: 1200px)";
$max_media_size_small: "(max-width: 1200px)";
$media_size_tiny: "(min-width: 769px) and (max-width: 920px)";
$max_media_size_tiny: "(max-width: 920px)";
$max_media_size_mobile: "(max-width: 768px)";

// Configurable
$base-font-size: 13px;
$site-background-color: $brand-white;
$max-page-width: 1500px;
$product-item-height: 120px;

$header-height: 85px;

.primary-font {
  font-family: $primary-font;
}

.accent-font {
  font-family: $accent-font;
}

.shadowed {
  box-shadow: 0 3px 15px -2px #00000049;
}

// Takes a specification for columns and creates a column grid that is compatible with all browsers
// Example input: "1fr 1fr 1fr"
@mixin make-column-grid($column-list){
  $grid-string: "";
  $num-columns: 1 !default;
  @each $col in $column-list { // Because IE is retarded
    $grid-string : $grid-string + $col + " ";
    & > *:nth-child(#{$num-columns}){
      -ms-grid-column: $num-columns;
    }
    $num-columns : $num-columns + 1;
  }
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: string.unquote($grid-string);
  grid-template-columns: string.unquote($grid-string);
}

// Takes a specification for rows and creates a row grid that is compatible with all browsers
@mixin make-row-grid($row-list){
  $grid-string: "";
  $num-rows: 1 !default;
  @each $row in $row-list { // Because IE is retarded
    $grid-string : $grid-string + $row + " ";
    & > *:nth-child(#{$num-rows}){
      -ms-grid-row: $num-rows;
    }
    $num-rows : $num-rows + 1;
  }
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: string.unquote($grid-string);
  grid-template-rows: string.unquote($grid-string);
}

// Defines an item within the grid starting at column $start and spanning $length columns
@mixin make-grid-item($start, $length){
  -ms-grid-column: $start !important;
  -ms-grid-column-span: $length !important;
  grid-column: $start / span $length;
}

html {
  @extend .primary-font;
  margin: 0;
  padding: 0;
  font-size: $base-font-size;
  color: $brand-black;
  background: $site-background-color;
}

input {
  @extend .primary-font;
}

h2 {
  @extend .accent-font;
  font-weight: 700;
  font-style: italic;
  color: $brand-black;
  font-size: 1.38em;
  padding: 4px 0;
  margin: 0;
}

button {
  @extend .primary-font;
  cursor: pointer;
}

body {
  margin: 0;
  padding: 0;
}

.status-text {
  font-size: 1.2em;
  font-weight: 600;
  padding: 8px;
  text-align: center;
}

.negative {
  color: $brand-primary;
  background: fade_out($brand-primary, 0.8);
}

.positive {
  color: $dark-grey;
  background: fade_out($green, 0.8);
}

.align-right {
  text-align: right;
}

.hidden-on-mobile {
  @media #{$max_media_size_tiny}{
    display: none !important;
  }
}

.red {
  color: $brand-primary;
}

.standard-page-size {
  max-width: $max-page-width;
  margin: 0 auto;
  padding: 60px;
  box-sizing: border-box;
  @media #{$max_media_size_medium}{
    padding: 48px;
  }
  @media #{$max_media_size_small}{
    padding: 32px;
  }
  @media #{$max_media_size_tiny}{
    padding: 28px;
  }
}

.show-on-mobile {
  display: none;
  @media #{$max_media_size_tiny}{
    display: initial;
  }
}

.loading {
  font-size: 1.6em;
  font-weight: 400;
  color: $brand-primary;
  padding: 40px;
}

.nothing-to-show {
  font-size: 1.6em;
  font-weight: 400;
  color: $brand-primary;
  padding: 40px 20px;
  @media #{$max_media_size_small}{
    padding: 40px 20px;
  }
}

.cart-item-date {
  font-weight: 500;
}

.dollar-sign {
  color: $brand-secondary;
  font-weight: 700;
  font-size: 1.2em;
  vertical-align: middle;
}

.price {
  font-weight: 700;
  vertical-align: middle;
  margin: 0 8px;
}

#input-errors {
  border: 1px solid $brand-primary;
  border-radius: 4px;
  background: fade_out($brand-primary, 0.85);
  padding: 20px;
  line-height: 1.6em;
  font-size: 1.2em;
  font-weight: 600;
  color: darken($brand-primary, 10%);
  @media #{$max-media-size_tiny}{
    text-align: center;
  }
}

@use "../styles.scss";

.product-item {
  @extend .shadowed;
  @include styles.make-column-grid(180px 1fr 220px);
  background: white;
  padding: 10px 16px;
  margin-bottom: 24px;
  @media #{styles.$max_media_size_tiny}{
    @include styles.make-column-grid(1fr);
    @include styles.make-row-grid(auto);
  }
  &.not-buyable {
    @include styles.make-column-grid(120px 1fr 220px);
    padding: 6px;
    font-size: 0.9em;
    margin-bottom: 6px;
    border-collapse: collapse;
  }
  &.bordered-item, &.not-buyable {
    box-shadow: none;
    border: 1px solid #bbb;
  }
}

.product-final-price {
  @extend .price;
  text-align: right;
  justify-content: flex-end;
  font-size: 1.7em;
  flex-direction: column;
}

.product-final-price, .quantity {
  display: flex;
  align-items: flex-end;
  padding: 10px 0;
}

.product-item-image {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  &:hover .product-image-veil {
    opacity: 1;
  }
  .product-image-veil {
    position: absolute;
    top: 50%;
    left: 50%;
    background: fade_out(styles.$brand-black, 0.5);
    text-align: center;
    padding: 4px;
    font-size: 0.9em;
    color: white;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s;
    cursor: pointer;
    user-select: none;
  }
  img {
    max-width: 85%;
    cursor: pointer;
    max-height: 200px;
    @media #{styles.$max_media_size_tiny}{
      //width: auto;
      max-height: 140px;
    }
  }
}

.product-missing-image {
  @extend .accent-font;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.13em;
  background: #bbb;
  color: styles.$dark-grey;
}

.product-item-info {
  align-content: center;
  @include styles.make-row-grid(30px auto);
  padding: 10px 0;
  @media #{styles.$max_media_size_tiny}{
    text-align: center;
    grid-column: 1 / span 2;
  }
}

.product-pricing-info {
  display: grid;
  @include styles.make-row-grid(1fr 1fr);
  @include styles.make-column-grid(1fr 1fr);
  @media #{styles.$max_media_size_tiny}{
    grid-column: 1 / span 2;
    padding: 0 16px;
  }
}

.product-id {
  @extend .accent-font;
  height: 36px;
  font-weight: 600;
  color: styles.$brand-secondary;
  font-size: 1.2em;
  padding: 0 26px;
  display: flex;
  letter-spacing: 0.8px;
  align-items: center;
  @media #{styles.$max_media_size_tiny}{
    justify-content: flex-start;
  }
}

.product-name-description {
  padding: 0 26px;
  font-size: 1.7em;
  font-weight: 700;
  .product-description {
    font-size: 0.8em;
    font-weight: 400;
  }
  @media #{styles.$max_media_size_tiny}{
    text-align: left;
  }
}

.product-name {
  text-decoration: underline;
}

.add-to-cart-container {
  grid-column: 1 / span 2;
  grid-row: 2 / span 1;
  padding: 8px 0;
  button {
    margin: 0 !important;
  }
}

.product-item-price {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  font-size: 1.7em;
  @media #{styles.$max_media_size_tiny}{
    justify-content: center;
  }
}

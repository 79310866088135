@use "../styles.scss";

.login-background {
  background: styles.$grey;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 192.49 111.13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0 110.05 15.25-26.31h-15.25v-.74h15.31l-15.31-26.55v-1.53l16 27.62 15.62-27-15.86-27.39h-15.76v-.77h47.38l-15.59-27h-31.68l-.11.19v-.57h32.47l15.82 27.41h31.17l-15.83-27.41h.89l15.83 27.41h31.21l-15.83-27.41h.9l15.56 27 15.63-27h.89l15.58 27 15.6-27h32.6v.56l-15.49 26.82h15.46v.77h-15.46l15.45 26.77v1.54l-16.08-27.94-15.61 27.05 15.83 27.43h15.9v.77h-47.49l15.59 27h31.53l.4-.69v1.08h-32.6l-15.85-27.45h-31.18l15.81 27.42h-.89l-15.81-27.42h-31.21l15.83 27.42h-.89l-15.61-27-15.6 27h-.89l-15.63-26.99-15.58 27h-32.42zm95.47-54.05h-31.16l-15.83 27.37 15.59 27zm48.15 27-15.62-27h-31.64l-15.58 27zm1.11-55.13 15.59 27 31.39-54.49h-31.15l-15.83 27.43zm-.45.77-15.58 27 6 10.37 9.84 17h31.16l-31.42-54.4zm-47.54 26.53h31.26l15.84-27.37-15.57-27.02zm-48-27 15.59 27h31.51l15.66-27zm-.74 54.42 15.6-27-15.78-27.44h-31.17zm-47.51 28.15h31.3l5.46-9.46 10.35-17.92-15.6-27.02z' fill='rgba(250,250,250,0.3)'/%3E%3C/svg%3E");
  box-sizing: border-box;
  background-size: 250px;
  padding: 60px;
  height: 100vh;
}

.login-keytrak-logo {
  background-position: center;
  height: 60px;
}

.login-container {
  @extend .shadowed;
  text-align: center;
  background: white;
  margin: 0 auto;
  max-width: 800px;
  padding: 40px;
  .spinner {
    width: 48px;
    height: 48px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
    background-image: url("/assets/keytrak-cart-spinner-no-circle-red.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
    animation: 1.4s ease spinner;
    animation-iteration-count: infinite;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.redirect-text {
  font-weight: 700;
  color: styles.$brand-primary;
  font-size: 2em;
  margin: 40px 0 12px;
}

.login-text {
  font-weight: 600;
  font-size: 1.4em;
  margin: 40px 0 12px;
}

.login-subtext {
  font-weight: 500;
  color: styles.$dark-grey;
  font-size: 1.2em;
  margin-bottom: 16px;
}

.auth-error {
  color: styles.$brand-primary;
  font-weight: 600;
  background: fade_out(styles.$brand-primary, 0.9);
  padding: 4px;
  margin: 12px 0;
}

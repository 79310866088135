@use '../styles';

.modal-backdrop {
  position: fixed;
  z-index: -10000;
  background: fade_out(styles.$brand-black, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: z-index 0.001s, opacity 0.24s ease;
}

.modal-body {
  position: absolute;
  padding: 0;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 800px;
  background: white;
  display: grid;
  grid-template-rows: 1fr auto;
  opacity: 0;
  transition: all 0.24s ease 0.1s;
  @media #{styles.$max-media-size_tiny}{
    width: 100%;
    max-width: 700px;
    box-sizing: border-box;
  }
}

#image-modal {
  .modal-body {
    @extend .shadowed;
    min-width: unset;
    max-width: 1000px;
    padding: 32px;
    background: styles.$brand-black;
  }
}

#alert-modal {
  .modal-body {
    min-width: 600px;
  }
  .modal-header {
    border: none;
    padding: 40px;
    text-align: center;
  }
  .modal-buttons {
    text-align: center;
  }
}

#order-again-modal {
  .modal-header {
    border: none;
    padding: 40px;
    text-align: center;
    font-size: 1.6em;
  }
  .outline-button {
    float: left;
  }
  &.narrow {
    .modal-body {
      min-width: 600px;
    }
    .modal-buttons {
      text-align: center;
    }
    .outline-button {
      float: none;
    }
  }
}


.modal-form {
  padding: 8px 32px;
  max-height: 70vh;
  overflow-y: auto;
}

.modal-saved-address {
  border: 1px solid styles.$grey;
  padding: 16px;
  font-size: 1.2em;
  margin: 16px 0;
  cursor: pointer;
  checkmark {
    float: right;
  }
  &:hover {
    border-color: black;
    background: #eee;
  }
  &.selected {
    border-color: styles.$brand-primary;
    background: fade_out(styles.$brand-primary, 0.9);
  }
}

.modal-header {
  margin: 0;
  padding: 24px 32px;
  border-bottom: 1px solid styles.$grey;
}

.visible {
  .modal-body {
    opacity: 1;
    top: 50%;
  }
  &.modal-backdrop {
    opacity: 1;
    z-index: 10000;
  }
}

.modal-buttons {
  padding: 16px 32px 32px;
  text-align: right;
}

.modal-close {
  color: styles.$dark-grey;
  cursor: pointer;
  position: absolute;
  font-size: 1.3em;
  top: 16px;
  right: 16px;
  z-index: 10;
  &:hover {
    color: styles.$brand-black;
  }
}

.dropdown-close {
  background: styles.$brand-black;
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  cursor: pointer;
  height: 32px;
  &:after, &:before {
    content: "";
    background: styles.$grey;
    width: 14px;
    height: 2px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:hover {
    &:after, &:before {
      background: white;
    }
  }
  &.light {
    background: transparent;
    &:before, &:after {
      background: black;
    }
    &:hover {
      &:before, &:after {
        background: styles.$brand-primary;
      }
    }
  }
}

@use "../styles.scss";

@mixin make-keytrak-icon($url, $size) {
  background-image: url("/assets/" + $url);
  background-size: $size;
  background-position: center;
  background-repeat: no-repeat;
}

.keytrak-go-icon {
  @include make-keytrak-icon("keytrak-go.svg", 50px 50px);
  height: 74px;
  @media #{styles.$max_media_size_tiny}{
    @include make-keytrak-icon("keytrak-go.svg", 36px 36px);
    display: inline-block;
    width: 74px;
    vertical-align: middle;
  }
}

.header-search-icon {
  @include make-keytrak-icon("keytrak-search.svg", contain);
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
  height: 16px;
  width: 16px;
}

.catalog-icon {
  @include make-keytrak-icon("catalog.svg", contain);
  line-height: styles.$header-height;
  height: 16px;
  width: 16px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}

.black-search-icon {
  @include make-keytrak-icon("keytrak-search-black.svg", contain);
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  height: 16px;
  width: 16px;
}

.header-cart-icon {
  @include make-keytrak-icon("keytrak-cart.svg", auto);
  height: styles.$header-height;
  line-height: styles.$header-height;
  background-size: auto styles.$header-height - 40px;
  cursor: pointer;
  z-index: 100;
  position: relative;
  &:hover, &.open {
    background-image: url("/assets/keytrak-cart-hover.svg");
  }
  &.spinning {
    background-image: url("/assets/keytrak-cart-spinner.svg") !important;
    animation: 1.4s ease spinner;
    animation-iteration-count: infinite;
  }
  &.white-icon {
    @include make-keytrak-icon("keytrak-cart-white.svg", auto);
    height: styles.$header-height + 30px;
    line-height: styles.$header-height + 30px;
    background-size: auto styles.$header-height - 10px;
  }
  &.has-items {
    &:after {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      top: 22px;
      right: 22px;
      background: styles.$brand-primary;
    }
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.keytrak-chevron-right {
  @include make-keytrak-icon("keytrak-chevron-right.svg", auto 1em);
  height: 1em;
  width: 1em;
  display: inline-block;
  vertical-align: middle;
  margin: 0 24px;
}

.keytrak-chevron-left {
  @extend .keytrak-chevron-right;
  transform: rotate(180deg);
  margin: 0 8px 0 0;
  vertical-align: middle;
  &.small {
    width: 0.7em;
    height: 0.7em;
    background-size: auto 0.7em;
  }
}

.circle-icon {
  @include make-keytrak-icon("keytrak-circle.svg", 20px);
  height: 28px;
  width: 28px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  &:hover {
    background-image: url("/assets/keytrak-circle-check-hover.svg");
  }
}

.add-to-cart-icon {
  @include make-keytrak-icon("keytrak-cart-add.svg", 20px);
  height: 14px;
  width: 14px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  &:hover {
    background-image: url("/assets/keytrak-cart-add-hover.svg");
  }
}

.circle-check-icon {
  @include make-keytrak-icon("keytrak-circle-check.svg", 20px);
  height: 28px;
  width: 28px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  &:hover {
    background-image: url("/assets/keytrak-circle-check-hover.svg");
  }
}

.static-check-icon {
  @include make-keytrak-icon("keytrak-circle-check.svg", 42px);
  height: 48px;
  width: 48px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
}

.static-spinner {
  @include make-keytrak-icon("keytrak-cart-spinner-no-circle.svg", 42px);
  height: 48px;
  width: 48px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
  animation: 1.4s ease spinner;
  animation-iteration-count: infinite;
}

.cart-item-delete {
  @include make-keytrak-icon("keytrak-trash.svg", auto 14px);
  height: 16px;
  width: 16px;
  position: absolute;
  top: 16px;
  right: 10px;
  cursor: pointer;
  &:hover {
    background-image: url("/assets/keytrak-trash-hover.svg");
  }
}

.empty-cart-icon {
  @include make-keytrak-icon("keytrak-trash-red.svg", auto 14px);
  background-position: bottom;
  height: 18px;
  width: 14px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
}

.quantity-decrease, .quantity-increase {
  position: relative;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 100%;
  &:after {
    height: 1px;
    background: styles.$grey;
    width: 8px;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.quantity-increase:before{
  height: 1px;
  background: styles.$grey;
  width: 8px;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) rotate(90deg);
}

.keytrak-logo, .keytrak-header-logo {
  background-image: url("/assets/keytrak.svg");
  background-size: auto styles.$header-height - 40px;
  background-position: 24px center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.keytrak-logo {
  background-size: contain;
  height: 80px;
  background-position: left;
  cursor: default;
}

.keytrak-exclaim {
  background-image: url("/assets/keytrak-exclaim.svg");
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: sub;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

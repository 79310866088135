@use "../styles.scss";

$input-height: 44px;
$search-filter-input-height: 48px;

label {
  font-weight: 600;
  font-size: 1.1em;
  display: block;
  margin-bottom: 4px;
}

.input-form {
  padding: 10px 0;
}

.input-group {
  padding: 12px 0;
}

.keytrak-input-group {
  padding: 12px 0;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 24px;
  @media #{styles.$max-media-size_tiny}{
    display: block;
  }
}

.keytrak-input-group, .input-group {
  label {
    &:after {
      content: " *";
      color: styles.$brand-primary;
    }
  }
  input, select {
    outline: none;
    width: 100%;
    border: 1px solid styles.$brand-black;
    background: styles.$brand-white;
    box-sizing: border-box;
    padding: 0 16px;
    height: $input-height;
    line-height: $input-height;
    font-size: 1.1em;
    &.invalid {
      border-color: styles.$brand-primary;
      background: fade_out(styles.$brand-primary, 0.9);
    }
  }
}

.search-button {
 font-family: styles.$primary-font;
  height: 60px;
  line-height: 60px;
  box-sizing: border-box;
  text-align: center;
  color: white;
  text-decoration: underline;
  background: styles.$brand-primary;
  padding: 0 36px;
  position: absolute;
  border: none;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.optional {
  label {
    &:after {
      content: " - Optional";
      color: styles.$brand-black;
    }
  }
  input, select {
    background: white;
  }
}

.checkbox-input-check {
  border: 2px solid styles.$grey;
  height: 16px;
  width: 16px;
  margin-right: 16px;
  margin-left: 8px;
  display: inline-block;
  border-radius: 100%;
  vertical-align: text-top;
  transition: all 0.2s ease;
}

.input-container.checked {
  .checkbox-input-check {
    border-color: styles.$brand-primary;
    background: styles.$brand-primary;
    box-shadow: inset 0 0 0 2px white;
  }
}

.hidden-input {
  display: none;
}

.toggle-container {
  border: 1px solid styles.$dark-grey;
  padding: 16px;
  cursor: pointer;
  margin: 0;
  margin-bottom: 8px;
  &.checked {
    border-color: styles.$brand-primary;
  }
  &.number-range-label {
    padding: 0;
    margin: 0;
  }
  &.checkbox-container {
    padding: 0;
    height: $input-height;
    line-height: $input-height;
    background: white;
    &:after {
      display: none;
    }
  }
}

.keytrak-number-filter-container {
  display: inline-flex;
  margin-right: 16px;
  height: $search-filter-input-height;
  line-height: $search-filter-input-height;
  vertical-align: middle;
  box-sizing: border-box;
}

.reset-filter-button {
  height: $search-filter-input-height;
  line-height: $search-filter-input-height;
  vertical-align: middle;
  color: styles.$brand-primary;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 16px;
}

.keytrak-number-filter-number {
  display: inline-block;
  color: black;
  padding: 4px;
  width: 62px;
  margin: 0 8px;
}

.checked .keytrak-number-filter-number{
  border-color: styles.$brand-primary;
}

.keytrak-number-filter-title {
  display: inline-block;
  margin-right: 16px;
}

.key-multiselect-container {
  display: flex;
}

